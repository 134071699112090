import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FiTrash } from 'react-icons/fi';
import { GoPencil } from 'react-icons/go';
import { FaRegCheckCircle } from 'react-icons/fa';

import Button from '../Button';
import * as S from './styles';
import Modal from '../Modal';
import Input from '../Input';
import Paginate from '../Paginate';
import { PageOptionsRequest } from '~/models/Common';
import { coproducerSchema } from '~/utils/schemas/coproducer';
import Coproducer, { ProducerType } from '~/services/Coproducer';
import { useLoading } from '~/hooks/Loading';
import { InputMask } from '..';

interface AssociatesListProps {
  professionalId: number;
}

export const AssociatesList: React.FC<AssociatesListProps> = ({
  professionalId,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { setLoading } = useLoading();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [ConfirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [pageOptions, setPageOptions] = useState<PageOptionsRequest>({
    page: 1,
  });
  const [newProducer, setNewProducer] = useState<boolean>(false);
  const [producesList, SetProducesList] = useState<ProducerType[] | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [producerId, setProducerId] = useState<number>(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(coproducerSchema),
  });

  const handleModalOpen = (): void => {
    setValue('name', '');
    setValue('cpf', '');
    setValue('cell_phone', '');
    setValue('email', '');

    setModalIsOpen(true);
  };

  const handleCoproducer = async (data: any): Promise<void> => {
    try {
      setLoading(true);
      const updatedData = {
        ...data,
        professionalId,
      };

      if (editMode) {
        await Coproducer.updateCoproducer(producerId, data);
      } else {
        await Coproducer.CreateCoproducer(updatedData);
      }

      setNewProducer(!newProducer);
      setLoading(false);
      setModalIsOpen(false);
      setConfirmModalIsOpen(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleInputChange = (
    field: 'name' | 'email' | 'cpf' | 'cell_phone',
    value: string,
  ): void => {
    setError(field as any, { type: '' });
    setValue(field, value);
  };

  const getAllCoproducers = async (): Promise<void> => {
    try {
      setLoading(true);

      const { data } = await Coproducer.getAllProducers(professionalId);

      SetProducesList(data);
      console.log(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleEditCoproducer = (editProducer: ProducerType): void => {
    setEditMode(true);
    setModalIsOpen(true);

    setValue('name', editProducer.name);
    setValue('cpf', editProducer.cpf);
    setValue('cell_phone', editProducer.cell_phone);
    setValue('email', editProducer.email);
    setProducerId(editProducer.id);
  };

  useEffect(() => {
    getAllCoproducers();
  }, [newProducer]);

  const formatCPF = (cpf: string): string => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const formatPhone = (phone: string): string => {
    return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  };

  return (
    <>
      <S.ListContainer>
        <S.TextContent>
          <h1>COPRODUTORES</h1>
          <p>Chame colaboradores para a sua produção e promoção de cursos</p>
        </S.TextContent>
        <Button
          type="button"
          IsGray
          size="220px"
          onClick={() => setIsVisible(!isVisible)}
        >
          {isVisible ? 'MOSTRAR MENOS' : 'MOSTRAR MAIS'}
        </Button>
      </S.ListContainer>

      <S.CoProducersContainer isVisible={isVisible}>
        <S.ButtonContent>
          <Button type="button" onClick={() => handleModalOpen()}>
            NOVO COPRODUTOR
          </Button>
        </S.ButtonContent>

        {!producesList ? (
          <h3>Você ainda não cadastrou nenhum coprodutor</h3>
        ) : (
          <>
            <S.Table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Telefone</th>
                  <th>CPF</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {producesList.map(producer => (
                  <tr key={producer.id}>
                    <td>{producer.name}</td>
                    <td>{producer.email}</td>
                    <td>
                      {producer.cell_phone
                        ? formatPhone(producer.cell_phone)
                        : ''}
                    </td>
                    <td>{producer.cpf ? formatCPF(producer.cpf) : ''}</td>
                    <td>
                      <S.ActionButton
                        type="button"
                        onClick={() => handleEditCoproducer(producer)}
                      >
                        <GoPencil />
                      </S.ActionButton>
                      <S.ActionButton type="button">
                        <FiTrash />
                      </S.ActionButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </S.Table>
            <Paginate
              {...{ setPageOptions }}
              pageCount={10}
              pageRangeDisplayed={0}
              noScroll
            />
          </>
        )}
      </S.CoProducersContainer>

      <S.ListContainer>
        <S.TextContent>
          <h1>AFILIADOS</h1>
          <p>
            Tenha afiliados para promover seus cursos através do link exclusivo
            dele. O afiliado irá ganhar uma porcentagem de cada venda.
          </p>
        </S.TextContent>
        <Button type="button" IsGray size="220px">
          MOSTRAR MAIS
        </Button>
      </S.ListContainer>

      <Modal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}>
        <h1>{editMode ? 'Editar coprodutor' : 'Novo coprodutor'}</h1>
        <S.ModalForm id="Coproducer" onSubmit={e => e.preventDefault()}>
          <S.Label>
            Nome:
            <Input
              value={watch('name')}
              name="Coname"
              onChange={e => handleInputChange('name', e.target.value)}
            />
            {errors.name && <p>{errors.name.message}</p>}
          </S.Label>

          <S.Label>
            E-mail:
            <Input
              value={watch('email')}
              name="Coemail"
              onChange={e => handleInputChange('email', e.target.value)}
              disabled={editMode}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </S.Label>

          <S.Label>
            Telefone:
            <InputMask
              value={watch('cell_phone')}
              mask="(99) 99999-9999"
              name="Cophone"
              onChange={e => handleInputChange('cell_phone', e.target.value)}
            />
            {errors.cell_phone && <p>{errors.cell_phone.message}</p>}
          </S.Label>

          <S.Label>
            CPF:
            <InputMask
              value={watch('cpf')}
              mask="999.999.999-99"
              name="Cocpf"
              onChange={e => handleInputChange('cpf', e.target.value)}
              disabled={editMode}
            />
            {errors.cpf && <p>{errors.cpf.message}</p>}
          </S.Label>

          <S.ModalButtonContent>
            <Button
              type="button"
              style={{ marginTop: '30px' }}
              onClick={handleSubmit(data => handleCoproducer(data))}
            >
              {editMode ? 'SALVAR ALTERAÇÕES' : 'CADASTRAR COPRODUTOR'}
            </Button>
          </S.ModalButtonContent>
        </S.ModalForm>
      </Modal>

      <Modal
        modalIsOpen={ConfirmModalIsOpen}
        setModalIsOpen={setConfirmModalIsOpen}
        withoutCloseButton
      >
        <S.ConfirmModalConntent>
          <FaRegCheckCircle />

          <h1>
            {editMode
              ? 'Informações de coprodutor alteradas com sucesso!'
              : 'Coprodutor cadastrado com sucesso!'}
          </h1>
          <h2>
            {!editMode &&
              'Um e-mail foi enviado para o coprodutor. Para poder receber a participação em cada curso, é necessário completar o cadastro presente no e-mail.'}
          </h2>
          <S.ModalButtonContent>
            {' '}
            <Button
              type="button"
              onClick={() => setConfirmModalIsOpen(false)}
              style={{ marginTop: '30px' }}
            >
              FINALIZAR
            </Button>
          </S.ModalButtonContent>
        </S.ConfirmModalConntent>
      </Modal>
    </>
  );
};
