import { AxiosPromise } from 'axios';
import api from './api';

interface CoproducerData {
  name: string;
  email: string;
  cell_phone: string;
  cpf: string;
  professional_id: string;
  created_at: string;
  updated_at: string;
  id: number;
  account_stripe: string;
}

export interface ProducerType {
  id: number;
  professional_id: number;
  name: string;
  email: string;
  cpf: string;
  cell_phone: string;
  is_active: number;
  account_stripe: string;
  created_at: string;
  updated_at: string;
}

export const Coproducer = {
  CreateCoproducer(data: any): AxiosPromise<CoproducerData> {
    return api.post('/coproducer/create', data);
  },

  getAllProducers(professionalId: number): AxiosPromise<ProducerType[]> {
    return api.get(`/coproducer/professional/${professionalId}`);
  },

  updateCoproducer(producerId: number, data: any): AxiosPromise<any> {
    return api.put(`/coproducer/${producerId}/update`, data);
  },
};

export default Coproducer;
